import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Fight() {
  return (
    <section id="fight">
      <StaticImage
        className="fight__background"
        src="../images/fightBackground.jpeg"
        alt=""
      />
      <div className="fight__overlay" />
      <div className="fight__content">
        <div className="fight__text">
          <h1 className="h2">Uni v Uni Student Boxing Charity Ball</h1>
          <p>
            Sign up to Star in our Uni v Uni Student Boxing Charity Ball and go
            Head to Head representing Durham University against your Newcastle
            University counterpart!
          </p>
          <p>
            This experience is free to take part, we cover everything including
            6 weeks of professional training so it doesn't cost you a penny!
          </p>
          <a
            href="https://tinyurl.com/boxingsignup"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up
          </a>
        </div>
        <div className="fight__poster">
          <StaticImage src="../images/fightPoster0.jpeg" alt="" />
          <StaticImage src="../images/fightPoster1.jpeg" alt="" />
        </div>
      </div>
    </section>
  );
}
