import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Partners() {
  return (
    <section id="partners">
      <div className="partners__content">
        <h1 className="h2">Partners</h1>
        <ul className="partners__grid">
          <li>
            <a
              className="invert"
              href="https://www.instagram.com/MarketShakerNewcastle"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage src="../images/partners0.png" alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/PumphreysNewcastle"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage src="../images/partners1.png" alt="" />
            </a>
          </li>
          <li>
            <a
              className="invert centered"
              href="https://www.instagram.com/stageoneevents_newcastle"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage src="../images/partners2.png" alt="" />
            </a>
          </li>
          <li>
            <a
              className="invert"
              href="https://www.instagram.com/FightClubNewcastle"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage src="../images/partners3.png" alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/LetsPlayDartsNewcastle"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage src="../images/partners4.png" alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/fixr.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage src="../images/partners5.png" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}
