import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Contact() {
  return (
    <section id="contact">
      <div className="contact__text">
        <h1 className="h2">Socials / Birthdays</h1>
        <p>
          Are you a Society, Sports Clubs or College planning your next night
          out?! …or just the Birthday Boy or Newly Single Sally thats planning
          your next BNO then get in touch!
        </p>
        <p>
          We love a good knees up as much as the next person, so if you wanna
          take that Birthday Boogie up a botch then hit up on ….
        </p>
        <p>
          We've got tables and booths a plenty! + All Organisers / Birthday
          Girls/Boys receive free entry too!
        </p>
        <p>
          Call us on{" "}
          <a className="animated_link" href="tel:+447940 143913">
            +447940 143913
          </a>
        </p>
      </div>
      <StaticImage
        className="contact__poster"
        src="../images/contact.png"
        alt=""
      />
    </section>
  );
}
