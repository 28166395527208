import { About } from "components/About";
import { Contact } from "components/Contact";
import { Fight } from "components/Fight";
import { Gallery } from "components/Gallery";
import { Hero } from "components/Hero";
import { Partners } from "components/Partners";
import { Reps } from "components/Reps";
import { SocialIcons } from "components/SocialIcons";
import { Tickets } from "components/Tickets";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

export default function IndexPage({ location }) {
  const title = "Bounce Events";
  const description =
    "The curators behind your favourite events in Durham since 2018!";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero background={<StaticImage src="../images/hero.png" alt="" />}>
        <div className="hero__text">
          <h1>Welcome to Bounce Events!</h1>
          <h2 className="h1">Durhams Leading Student Events Company!</h2>
          <h3>
            The curators behind your favourite events in Durham since 2018!
          </h3>
        </div>
        <div />
        <SocialIcons />
      </Hero>
      <About />
      <Partners />
      <Tickets />
      <Fight />
      <Reps />
      <Gallery />
      <Contact />
    </>
  );
}
