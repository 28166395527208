import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function About() {
  return (
    <section id="about">
      <div className="about__text">
        <h1 className="h2">About us</h1>
        <p>
          Since 2010 we have been running some of the North Easts Biggest and
          Longest standing student events! Spanning across both Durham and
          Newcastle Universities we bring a one of a kind party atmosphere to
          some of the best clubs and event spaces in Durham and the North East!
        </p>
        <p>
          So whether its a 2,000 Person Darts Festival, Student Boxing Charity
          Balls, Bingo Raves, Abba Tributes, Gucci Gang takeovers or DJ Led
          Raves we have done it all! With past guests including DJ Ez, Folamour,
          Purple Disco Machine &amp; Bellaire we are you one stop weekly party
          shop!
        </p>
        <p>
          Students ourselves, since graduating some 12 years ago we have
          established a track record of success but more importantly a
          reputation of treating everybody who attends our events or works with
          us fairly and responsibly proving to be the perfect choice for an
          unforgettable night our or part time job whilst studying in Durham!
        </p>
      </div>
      <StaticImage
        className="about__poster"
        src="../images/logoBlock.png"
        alt=""
      />
    </section>
  );
}
